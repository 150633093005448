import React, { useRef } from 'react';
import classNames from 'classnames/bind';
import useMountEffect from '@restart/hooks/useMountEffect';

import styles from './20240410.module.scss';

const cx = classNames.bind(styles);

const Page = () => {
  const accessToken = useRef();

  useMountEffect(() => {
    window.ReactNativeWebView?.postMessage(JSON.stringify({ command: 'accessToken' }));

    window.addEventListener('message', (e) => {
      const message = JSON.parse(e.data);

      if (message.command === 'accessToken') {
        accessToken.current = message.data;
      }
    });
  });

  const moveTo = () => {
    window.ReactNativeWebView?.postMessage(
      JSON.stringify({
        command: 'url',
        data: 'https://youtu.be/NNqUkM_gybA?feature=shared',
      }),
    );

    window.ReactNativeWebView?.postMessage(JSON.stringify({ command: 'badge' }));
  };

  return (
    <div className={cx('container')}>
      <img src={require('../../assets/images/badge/20240410.jpg')} alt="PPL 광고 시청" />
      <div className={cx('text')}>
        2024년 더벤티 New 모델은?
        <br />
        미미미누 이사님이 소개하는
        <br />
        더벤티 모델을 지금 바로 확인해보세요!
      </div>
      <div onClick={moveTo} className={cx('button')}>
        영상 보러가기
      </div>
    </div>
  );
};

export default Page;
