import React from 'react';
import classNames from 'classnames/bind';

import styles from './common.module.scss';

const cx = classNames.bind(styles);

const Page = () => {
  return (
    <div className={cx('container')}>
      <a
        href="theventi://external-link?url=https://www.instagram.com/p/C0ad9-FSSrb/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA=="
        className={cx('banner')}>
        <img src={require('../../assets/images/webview/20240119.jpg')} alt="" />
      </a>
    </div>
  );
};

export default Page;
