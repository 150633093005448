import React from 'react';
import classNames from 'classnames/bind';
import { useSearchParams } from 'react-router-dom';

import styles from './common.module.scss';

const cx = classNames.bind(styles);

const Page = () => {
  const [searchParams] = useSearchParams();

  const moveTo = () => {
    // 회원번호
    const memberNo = searchParams.get('memberNo');

    if (memberNo) {
      window.location.href = 'theventi://external-link?url=https://youtu.be/6rbtRqEQ2Cs';
    } else {
      window.ReactNativeWebView?.postMessage(
        JSON.stringify({
          type: 'NAVIGATE',
          screenName: 'Login',
        }),
      );
    }
  };

  return (
    <div className={cx('container')}>
      <div className={cx('banner')} onClick={moveTo}>
        <img src={require('../../assets/images/webview/20240909.jpg')} alt="" />
      </div>
    </div>
  );
};

export default Page;
