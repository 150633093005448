import React from 'react';
import classNames from 'classnames/bind';

import styles from './common.module.scss';

const cx = classNames.bind(styles);

const Page = () => {
  return (
    <div className={cx('container')}>
      <a href="theventi://external-link?url=https://smore.im/quiz/OuXgxrrGRj" className={cx('banner')}>
        <img src={require('../../assets/images/webview/20240315.jpg')} alt="" />
      </a>
    </div>
  );
};

export default Page;
