import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { useParams, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import useMountEffect from '@restart/hooks/useMountEffect';

import styles from './index.module.scss';

const cx = classNames.bind(styles);

const isReal = window.location.hostname === 'theventi.apps.flyground.co.kr';
const HOST_URL = isReal ? 'https://theventi.apis.flyground.co.kr' : 'https://alpha-theventi.apis.flyground.co.kr';

const Page = () => {
  const params = useParams();
  const [searchParams] = useSearchParams();
  const [couponpack, setCouponpack] = useState();
  const [isAvailable, setAvailable] = useState(false);
  const [message, setMessage] = useState();
  const [showModal, setShowModal] = useState(false);

  // 쿠폰팩 ID
  const id = params.id;
  // 회원번호
  const memberNo = searchParams.get('memberNo'); // 10030000007730565

  useMountEffect(() => {
    // API: 쿠폰팩 상세 조회
    axios
      .get(`${HOST_URL}/v1/promo/couponpack/${id}`)
      .then((result) => result.data)
      .then(({ success, data }) => {
        if (success) {
          setCouponpack(data);
        }
      });
  });

  useEffect(
    () => {
      if (couponpack === undefined) return;

      // 회원번호 없는 경우
      if (memberNo === null || memberNo === '') {
        setMessage('로그인 후 참여 가능합니다.');
        return;
      }

      // API: 쿠폰발급 가능여부 조회
      axios
        .get(`${HOST_URL}/v1/promo/couponpack/${id}/available?memberNo=${memberNo}`)
        .then((result) => result.data)
        .then(({ success, data }) => {
          if (success) {
            if (data.available) {
              setAvailable(true);
            } else if (data.code === -3007) {
              setMessage('이미 쿠폰이 발급되었습니다.');
            } else if (data.code === -3010) {
              setMessage('쿠폰 발급 기간이 아닙니다.');
            } else if (data.code === -3012) {
              setMessage('이벤트 참여대상이 아닙니다.');
            } else if (data.code === -3013) {
              setMessage('유효하지 않은 이벤트입니다.');
            } else {
              setMessage('잠시 후 다시 시도해주세요.');
            }
            return;
          }
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [couponpack],
  );

  const getCoupons = () => {
    if (!isAvailable) {
      return;
    }

    // API: 쿠폰발급 요청
    axios
      .post(`${HOST_URL}/v1/promo/couponpack/${id}/issue?memberNo=${memberNo}`)
      .then((result) => result.data)
      .then(({ success, data }) => {
        setAvailable(false);

        if (success) {
          setShowModal(true);
        } else {
          if (data.code === -3004) {
            setMessage('쿠폰 발급 수량을 초과했습니다.');
          } else {
            setMessage('잠시 후 다시 시도해주세요.');
          }
        }
      });
  };

  const moveToOrder = () => {
    setShowModal(false);

    window.ReactNativeWebView?.postMessage(
      JSON.stringify({
        type: 'NAVIGATE',
        screenName: 'PickupShopList',
      }),
    );
  };

  if (couponpack === undefined) {
    return null;
  }

  return (
    <div className={cx('container')}>
      <img src={couponpack.image1Url} className={cx('image')} alt={couponpack.title} />
      <div className={cx('buttonWrapper')} style={{ backgroundColor: couponpack.buttonBackgroundColor }}>
        {(isAvailable || message !== undefined) && (
          <div
            className={cx('button')}
            onClick={getCoupons}
            style={{
              color: couponpack.buttonTextColor,
              backgroundColor: couponpack.buttonColor,
            }}>
            {isAvailable ? '쿠폰 다운받기' : message}
          </div>
        )}
      </div>
      <img src={couponpack.image2Url} className={cx('image')} alt={couponpack.title} />
      {showModal && (
        <div className={cx('dim')}>
          <div className={cx('modal')}>
            <div className={cx('message')}>쿠폰이 발급되었습니다.</div>
            <div className={cx('button')} onClick={moveToOrder}>
              주문하러가기
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Page;
