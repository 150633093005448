import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Bingo from './pages/bingo';
import Survey from './pages/survey';
import CouponPack from './pages/couponpack';
import Badge20240310 from './pages/badge/20240310';
import Badge20240410 from './pages/badge/20240410';
import Badge20240510 from './pages/badge/20240510';
import Badge20240610 from './pages/badge/20240610';
import Badge20240710 from './pages/badge/20240710';
import Badge20240810 from './pages/badge/20240810';
import Badge20240910 from './pages/badge/20240910';
import Badge20241010 from './pages/badge/20241010';
import Badge20241110 from './pages/badge/20241110';
import WebView20230331 from './pages/webview/20230331';
import WebView20230419 from './pages/webview/20230419';
import WebView20230426 from './pages/webview/20230426';
import WebView20230517 from './pages/webview/20230517';
import WebView20230721 from './pages/webview/20230721';
import WebView20230810 from './pages/webview/20230810';
import WebView20231130 from './pages/webview/20231130';
import WebView20240118 from './pages/webview/20240118';
import WebView20240119 from './pages/webview/20240119';
import WebView20240315 from './pages/webview/20240315';
import WebView20240408 from './pages/webview/20240408';
import WebView20240626 from './pages/webview/20240626';
import WebView20240812 from './pages/webview/20240812';
import WebView20240909 from './pages/webview/20240909';

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/bingo/:id" element={<Bingo />} />
        <Route exact path="/survey/:id" element={<Survey />} />
        <Route exact path="/couponpack/:id" element={<CouponPack />} />
        <Route exact path="/badge/20240310" element={<Badge20240310 />} />
        <Route exact path="/badge/20240410" element={<Badge20240410 />} />
        <Route exact path="/badge/20240510" element={<Badge20240510 />} />
        <Route exact path="/badge/20240610" element={<Badge20240610 />} />
        <Route exact path="/badge/20240710" element={<Badge20240710 />} />
        <Route exact path="/badge/20240810" element={<Badge20240810 />} />
        <Route exact path="/badge/20240910" element={<Badge20240910 />} />
        <Route exact path="/badge/20241010" element={<Badge20241010 />} />
        <Route exact path="/badge/20241110" element={<Badge20241110 />} />
        <Route exact path="/webview/20230331" element={<WebView20230331 />} />
        <Route exact path="/webview/20230419" element={<WebView20230419 />} />
        <Route exact path="/webview/20230426" element={<WebView20230426 />} />
        <Route exact path="/webview/20230517" element={<WebView20230517 />} />
        <Route exact path="/webview/20230721" element={<WebView20230721 />} />
        <Route exact path="/webview/20230810" element={<WebView20230810 />} />
        <Route exact path="/webview/20231130" element={<WebView20231130 />} />
        <Route exact path="/webview/20240118" element={<WebView20240118 />} />
        <Route exact path="/webview/20240119" element={<WebView20240119 />} />
        <Route exact path="/webview/20240315" element={<WebView20240315 />} />
        <Route exact path="/webview/20240408" element={<WebView20240408 />} />
        <Route exact path="/webview/20240626" element={<WebView20240626 />} />
        <Route exact path="/webview/20240812" element={<WebView20240812 />} />
        <Route exact path="/webview/20240909" element={<WebView20240909 />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
