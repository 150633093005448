import React, { useState } from 'react';
import classNames from 'classnames/bind';
import { useParams, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import useMountEffect from '@restart/hooks/useMountEffect';
import useUpdateEffect from '@restart/hooks/useUpdateEffect';

import styles from './index.module.scss';

const cx = classNames.bind(styles);

const isReal = window.location.hostname === 'theventi.apps.flyground.co.kr';
const HOST_URL = isReal ? 'https://theventi.apis.flyground.co.kr' : 'https://alpha-theventi.apis.flyground.co.kr';

const Page = () => {
  const params = useParams();
  const [searchParams] = useSearchParams();
  const [bingo, setBingo] = useState();
  const [bingoCount, setBingoCount] = useState(0);
  const [status, setStatus] = useState([false, false, false, false, false, false, false, false, false]);
  const [coupons, setCoupons] = useState([false, false, false]);

  // 빙고 ID
  const id = params.id;
  // 회원번호
  const memberNo = searchParams.get('memberNo'); // 10030000007730544

  useMountEffect(() => {
    // API: 빙고 상세 조회
    axios
      .get(`${HOST_URL}/v1/promo/bingo/${id}`)
      .then((result) => result.data)
      .then(({ success, data }) => {
        console.debug(success, data);

        if (success) {
          setBingo(data);
        }
      });

    // 회원번호 없는 경우
    if (!memberNo) return;

    // API: 빙고 상태조회
    axios
      .get(`${HOST_URL}/v1/promo/bingo/${id}/${memberNo}/status`)
      .then((result) => result.data)
      .then(({ success, data }) => {
        console.debug(success, data);

        if (success) {
          const nextStatus = [];
          for (const row of data.bingoTable) {
            for (const cell of row) {
              nextStatus.push(cell);
            }
          }
          setStatus(nextStatus);
          setBingoCount(data.bingoCount);
        }
      });
  });

  useUpdateEffect(() => {
    // 회원번호 없는 경우
    if (!memberNo) return;

    updateCoupons();
  }, [bingo]);

  const updateCoupons = () => {
    // API: 쿠폰발급 이력조회
    axios
      .get(`${HOST_URL}/v1/promo/bingo/${id}/${memberNo}/coupon`)
      .then((result) => result.data)
      .then(({ success, data }) => {
        console.debug(success, data);

        if (success) {
          const nextCoupons = [];
          bingo.couponIds.forEach((couponId) => {
            if (couponId) {
              nextCoupons.push(data.includes(couponId));
            }
          });
          setCoupons(nextCoupons);
        }
      });
  };

  const requestCoupon = (bingoCount) => {
    // 회원번호 없는 경우
    if (!memberNo) {
      alert('로그인 후 참여 가능합니다.');
      return;
    }

    // API: 쿠폰발급요청
    axios
      .post(`${HOST_URL}/v1/promo/bingo/${id}/${memberNo}/coupon`, { bingoCount })
      .then((result) => result.data)
      .then(({ success, data }) => {
        console.debug(success, data);

        if (success) {
          alert('쿠폰이 발급되었습니다.\n쿠폰함을 확인해주세요.');
          window.location.reload();
        } else {
          // 기 발급 쿠폰
          if (data.code === -3020) {
            alert('이 쿠폰은 이미 발급되었습니다.');
            updateCoupons();
          }
          // 빙고 완성 전
          else if (data.code === -3021) {
            alert(`아직 ${bingoCount}줄 빙고가 완성되지 않았습니다.`);
          }
        }
      });
  };

  if (bingo === undefined) return;

  return (
    <div className={cx('container')}>
      <img src={bingo.image1Url} alt="BINGO" className={cx('top')} />
      <div className={cx('wrapper')} style={{ backgroundColor: bingo.boardBackgroundColor }}>
        <div className={cx('board')}>
          <div className={cx('cells')}>
            {bingo.cells.map((cell, index) => (
              <div key={index} className={cx('cell')}>
                <img src={cell.imageUrl} alt={cell.name} />
                <div className={cx('name')}>{cell.name}</div>
                {status[index] && (
                  <div className={cx('overlay')}>
                    <img
                      src={require('../../assets/images/bingo/stamping.png')}
                      srcSet={`${require('../../assets/images/bingo/stamping@2x.png')} 2x`}
                      alt="BINGO!!"
                    />
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
        <div className={cx('coupons')}>
          <div
            className={cx(['coupon', { issued: coupons[0], clickable: !coupons[0] && bingoCount === 1 }])}
            onClick={coupons[0] ? null : () => requestCoupon(1)}>
            <div className={cx('title')}>
              빙고
              <br />
              1줄 완성
            </div>
            <div className={cx('label')}>{coupons[0] ? '발급완료 ' : '쿠폰받기'}</div>
          </div>
          <div
            className={cx(['coupon', { issued: coupons[1], clickable: !coupons[1] && bingoCount === 3 }])}
            onClick={coupons[1] ? null : () => requestCoupon(3)}>
            <div className={cx('title')}>
              빙고
              <br />
              3줄 완성
            </div>
            <div className={cx('label')}>{coupons[1] ? '발급완료 ' : '쿠폰받기'}</div>
          </div>
          <div
            className={cx(['coupon', { issued: coupons[2], clickable: !coupons[2] && bingoCount === 8 }])}
            onClick={coupons[2] ? null : () => requestCoupon(8)}>
            <div className={cx('title')}>
              빙고
              <br />
              전체 완성
            </div>
            <div className={cx('label')}>{coupons[2] ? '발급완료 ' : '쿠폰받기'}</div>
          </div>
        </div>
      </div>
      <img src={bingo.image2Url} alt="BINGO" className={cx('bottom')} />
    </div>
  );
};

export default Page;
