import React from 'react';
import classNames from 'classnames/bind';

import styles from './common.module.scss';

const cx = classNames.bind(styles);

const Page = () => {
  const moveTo = () => {
    window.ReactNativeWebView?.postMessage(
      JSON.stringify({
        type: 'NAVIGATE',
        screenName: 'EventDetail',
        params: { id: 'vYXDdHcI2ljBC95bxDzi' },
      }),
    );
  };

  return (
    <div className={cx('container')}>
      <div className={cx('banner')} onClick={moveTo}>
        <img src={require('../../assets/images/webview/20230517.jpg')} alt="" />
      </div>
    </div>
  );
};

export default Page;
