import React, { useRef } from 'react';
import classNames from 'classnames/bind';
import useMountEffect from '@restart/hooks/useMountEffect';

import styles from './20240910.module.scss';

const cx = classNames.bind(styles);

const Page = () => {
  const accessToken = useRef();

  useMountEffect(() => {
    window.ReactNativeWebView?.postMessage(JSON.stringify({ command: 'accessToken' }));

    window.addEventListener('message', (e) => {
      const message = JSON.parse(e.data);

      if (message.command === 'accessToken') {
        accessToken.current = message.data;
      }
    });
  });

  const moveTo = () => {
    window.ReactNativeWebView?.postMessage(
      JSON.stringify({
        command: 'url',
        data: 'https://oxfamwalk.or.kr/2024/',
      }),
    );

    window.ReactNativeWebView?.postMessage(JSON.stringify({ command: 'badge' }));
  };

  return (
    <div className={cx('container')}>
      <img src={require('../../assets/images/badge/20240910.jpg')} alt="옥스팜워크 알아보기" />
      <div className={cx('text')}>
        옥스팜 이하늬 홍보대사와 함께
        <br />
        아름다운 자작나무 숲을 걸으며
        <br />
        여성과 소녀를 위한 발걸음에 동참해주세요!
      </div>
      <div onClick={moveTo} className={cx('button')}>
        옥스팜워크 알아보기
      </div>
      <div className={cx('desc')}>
        벤텐데이 기부 미션의 기부금은
        <br />
        국제구호개발기구 옥스팜에 기부됩니다.
      </div>
    </div>
  );
};

export default Page;
