import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { useParams, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import useMountEffect from '@restart/hooks/useMountEffect';

import styles from './index.module.scss';

const cx = classNames.bind(styles);

const isReal = window.location.hostname === 'theventi.apps.flyground.co.kr';
const HOST_URL = isReal ? 'https://theventi.apis.flyground.co.kr' : 'https://alpha-theventi.apis.flyground.co.kr';

const Page = () => {
  const params = useParams();
  const [searchParams] = useSearchParams();
  const [survey, setSurvey] = useState();
  const [message, setMessage] = useState();

  // 설문조사 ID
  const id = params.id;
  // 회원번호
  const memberNo = searchParams.get('memberNo'); // 10030000007730565

  useMountEffect(() => {
    // API: 설문조사 상세 조회
    axios
      .get(`${HOST_URL}/v1/promo/surveys/${id}`)
      .then((result) => result.data)
      .then(({ success, data }) => {
        if (success) {
          setSurvey(data);
        }
      });
  });

  useEffect(
    () => {
      if (survey === undefined) return;

      // 회원번호 없는 경우
      if (memberNo === null || memberNo === '') {
        setMessage('로그인 후 참여 가능합니다.');
        return;
      }

      // API: 설문참여 가능여부 조회
      axios
        .get(`${HOST_URL}/v1/promo/surveys/${id}/available?memberNo=${memberNo}`)
        .then((result) => result.data)
        .then(({ success, data }) => {
          if (success) {
            if (data.available) {
              window.location.href = `${survey.url}&${survey.memberNoParamName}=${memberNo}`;
            } else if ([-3003, -3012].includes(data.code)) {
              setMessage(<span>설문 참여대상이 아닙니다.</span>);
            } else if (data.code === -3005) {
              setMessage(<span>이미 참여를 완료하였습니다.</span>);
            } else if (data.code === -3011) {
              setMessage(<span>유효하지 않은 설문입니다.</span>);
            }
            return;
          }
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [survey],
  );

  return (
    <div className={cx('container')}>
      {survey && <img src={survey.imageUrl} alt={survey.title} />}
      {message && (
        <div className={cx('messageWrapper')}>
          <div className={cx('box')}>{message}</div>
        </div>
      )}
    </div>
  );
};

export default Page;
