import React, { useRef, useState } from 'react';
import classNames from 'classnames/bind';
import Lottie from 'react-lottie';
import useMountEffect from '@restart/hooks/useMountEffect';

import styles from './20240710.module.scss';
import * as animationData from './animation.json';

const cx = classNames.bind(styles);

const Page = () => {
  const [number, setNumber] = useState(0);
  const accessToken = useRef();

  useMountEffect(() => {
    window.ReactNativeWebView?.postMessage(JSON.stringify({ command: 'accessToken' }));

    window.addEventListener('message', (e) => {
      const message = JSON.parse(e.data);

      if (message.command === 'accessToken') {
        accessToken.current = message.data;
      }
    });
  });

  const select = (nextNumber) => {
    if (number === 1) return;

    setNumber(nextNumber);
    if (nextNumber === 1) {
      setTimeout(() => {
        window.ReactNativeWebView?.postMessage(JSON.stringify({ command: 'badge' }));
      }, 3000);
    }
  };

  return (
    <div className={cx('container')}>
      <div className={cx('box')}>
        <div className={cx('label')}>벤티 Quiz</div>
        <div className={cx('question')}>
          더벤티에서 7월 10일
          <br />
          새로 출시된 디저트는?
        </div>
      </div>
      {number === 0 && <div className={cx('message')}>정답을 선택해 주세요.</div>}
      {[2, 3, 4].includes(number) && <div className={cx(['message', 'animate'])}>다시 한번 도전?</div>}
      {number === 1 && <div className={cx('message')}>정답입니다!</div>}
      <ul>
        <li onClick={() => select(1)} className={cx(['tint', { select: number === 1 }])}>
          <div className={cx(['symbol', 'symbol-1'])}>&nbsp;</div>
          <div className={cx('text')}>고소하고 부드러운 커피번 2종</div>
        </li>
        <li onClick={() => select(2)} className={cx(['gray', { select: number === 2 }])}>
          <div className={cx(['symbol', 'symbol-2'])}>&nbsp;</div>
          <div className={cx('text')}>럭키비키 행운 포춘쿠키</div>
        </li>
        <li onClick={() => select(3)} className={cx(['gray', { select: number === 3 }])}>
          <div className={cx(['symbol', 'symbol-3'])}>&nbsp;</div>
          <div className={cx('text')}>두바이 초콜릿 빙수</div>
        </li>
        <li onClick={() => select(4)} className={cx(['gray', { select: number === 4 }])}>
          <div className={cx(['symbol', 'symbol-4'])}>&nbsp;</div>
          <div className={cx('text')}>고수 마라 스파게티</div>
        </li>
      </ul>
      {number === 1 && (
        <div className={cx('animation')}>
          <Lottie
            options={{
              loop: false,
              autoplay: true,
              animationData,
            }}
            height={320}
            width={320}
          />
        </div>
      )}
    </div>
  );
};

export default Page;
